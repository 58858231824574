import React from 'react';

const ContactPage = () => {
  return (
    <div>
      <h1>Contact</h1>
      <p>Contact information and form will be here.</p>
      {/* Дополнительные детали для страницы контактов */}
    </div>
  );
};

export default ContactPage;