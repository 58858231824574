import React from 'react';

const CountriesPage = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Страны</h1>
      <p>Информация о различных странах и возможностях трудоустройства.</p>
      {/* Здесь будет список или карточки стран */}
    </div>
  );
};

export default CountriesPage;
